import {
  LearningOutcomesQuery,
  ReviewCard,
  SkillAreaQuery,
  SkillClassification,
} from '@mono/data-codegen/client/graphql-gateway/graphql';
import { parseDate } from '@mono/util-date';
import { format } from 'date-fns';
import { ReadonlyURLSearchParams } from 'next/navigation';

import { learningOutcome1 } from './mocks';
import {
  LearningOutcomeContentModules,
  LearningOutcomeLevels,
  LearningOutcomePaths,
  LearningOutcomeSkills,
  LearningOutcomeTracks,
  LearningOutcomeWithProgress,
  RelatedLevelOneLearningOutcomes,
  RelatedLevelThreeLearningOutcomes,
  RelatedLevelTwoLearningOutcomes,
} from './types';

export const learningOutcomeLabels = {
  [LearningOutcomeLevels.One]: 'Subskill',
  [LearningOutcomeLevels.Two]: 'Skill',
  [LearningOutcomeLevels.Three]: 'Skill set',
};

export const getSkillsAreaPath = (skillSlug?: string) => {
  return skillSlug ? `/skill-areas/${skillSlug}` : '/';
};

export const getLearningOutcomePath = (id?: string, skillSlug?: string) => {
  if (!id) return '/';

  // add the skill query param for the dynamic breadcrumb
  return skillSlug
    ? `/learning-outcomes/${id}?skill=${skillSlug}`
    : `/learning-outcomes/${id}`;
};

export const skillsTrackingTabPath = '/learn?page=skills';

export const getLearningOutcomeAssessmentPath = (id?: string) => {
  return id ? `/learning-outcome-assessments/${id}` : '/';
};

export const getBreadcrumbContent = (
  searchParams: ReadonlyURLSearchParams,
  isSkillAreaPage?: boolean,
  currentContainerTitle?: string | null,
  skills?: LearningOutcomeSkills
) => {
  const skillParam = searchParams.get('skill');

  if (skillParam) {
    const skillFromQueryParam = skills?.find(
      (skill) => skill?.slug === skillParam
    );

    return [
      {
        title: 'Skills tracking',
        href: skillsTrackingTabPath,
        trackingTarget: 'skills_tab',
      },
      {
        title: skillFromQueryParam?.title,
        href: getSkillsAreaPath(skillParam),
        trackingTarget: 'skills_area',
      },
      {
        title: currentContainerTitle || '',
        href: '',
        trackingTarget: 'skills_outcome',
      },
    ];
  }

  return [
    {
      title: 'Skills tracking',
      href: skillsTrackingTabPath,
      trackingTarget: 'skills_tab',
    },
    {
      title: currentContainerTitle || '',
      href: '',
      trackingTarget: isSkillAreaPage ? 'skills_area' : 'skills_outcome',
    },
  ];
};

export const formatDate = (
  date?: string | null,
  displayShortenedDate?: boolean
) => {
  if (!date) return '';

  return displayShortenedDate
    ? format(parseDate(date), 'MMM d')
    : format(parseDate(date), 'MMM d, yyyy');
};

export const getSkillsListData = (skills?: LearningOutcomeSkills) => {
  if (!skills?.length)
    return {
      showMoreSkillsText: false,
      remainingSkillsAmount: 0,
    };
  const maxSkillsToShow = 6;

  return {
    showMoreSkillsText: Boolean(skills?.length > maxSkillsToShow),
    remainingSkillsAmount: skills?.length - maxSkillsToShow,
  };
};

const extractContentModulesFromLearningOutcome = (
  learningOutcome?: LearningOutcomeWithProgress
) => {
  const isLevelOne = learningOutcome?.level === LearningOutcomeLevels.One;
  const isLevelTwo = learningOutcome?.level === LearningOutcomeLevels.Two;

  if (isLevelOne)
    return (learningOutcome as RelatedLevelOneLearningOutcomes[number])
      ?.contentModules;

  if (isLevelTwo)
    return (
      learningOutcome as RelatedLevelTwoLearningOutcomes[number]
    )?.tracks?.flatMap((track) => {
      return track?.contentModules;
    });

  return (
    learningOutcome as RelatedLevelThreeLearningOutcomes[number]
  )?.paths?.flatMap((path) => {
    return path?.contentModules;
  });
};

export const getContentModuleData = (
  contentModules?: LearningOutcomeContentModules
) => {
  if (
    !contentModules?.length ||
    contentModules?.every((module) => module == null)
  )
    return {
      skills: [],
      reviewCards: [],
      tracks: [],
      paths: [],
    };

  return contentModules?.reduce(
    (
      acc: {
        skills?: LearningOutcomeSkills;
        reviewCards?: ReviewCard[] | null;
        tracks?: LearningOutcomeTracks | null;
        paths: LearningOutcomePaths | null;
      },
      module
    ) => {
      const { skills = [], reviewCards = [], tracks = [], paths = [] } = module;
      acc.skills?.push(...(skills || []));
      acc.reviewCards?.push(...((reviewCards as ReviewCard[]) || []));
      acc.tracks?.push(...((tracks as LearningOutcomeTracks) || []));
      acc.paths?.push(...((paths as LearningOutcomePaths) || []));
      return acc;
    },
    {
      skills: [],
      reviewCards: [],
      tracks: [],
      paths: [],
    }
  );
};

export const getLearningOutcomeCardSkills = (
  learningOutcome?: LearningOutcomeWithProgress
) => {
  if (!learningOutcome?.level) return { skills: [] };
  const contentModules =
    extractContentModulesFromLearningOutcome(learningOutcome);

  const { skills } = getContentModuleData(
    contentModules as LearningOutcomeContentModules
  );

  return {
    skills:
      skills?.filter((value, index, self) => {
        return self.findIndex((v) => v?.title === value?.title) === index;
      }) || [],
  };
};

export const getRelatedLearningOutcomes = (
  learningOutcomeLevel?: number | null,
  levelOneLearningOutcomes?: RelatedLevelOneLearningOutcomes,
  levelTwoLearningOutcomes?: RelatedLevelTwoLearningOutcomes,
  levelThreeLearningOutcomes?: RelatedLevelThreeLearningOutcomes
) => {
  if (
    (!levelOneLearningOutcomes &&
      !levelTwoLearningOutcomes &&
      !levelThreeLearningOutcomes) ||
    !learningOutcomeLevel
  ) {
    return {
      childLearningOutcomes: [],
      relatedLearningOutcomes: [],
    };
  }

  if (learningOutcomeLevel === LearningOutcomeLevels.One) {
    return {
      childLearningOutcomes: [],
      relatedLearningOutcomes: [
        levelThreeLearningOutcomes as LearningOutcomeWithProgress[],
        levelTwoLearningOutcomes as LearningOutcomeWithProgress[],
      ].flat(),
    };
  }
  if (learningOutcomeLevel === LearningOutcomeLevels.Two) {
    return {
      childLearningOutcomes:
        levelOneLearningOutcomes as LearningOutcomeWithProgress[],
      relatedLearningOutcomes:
        levelThreeLearningOutcomes as LearningOutcomeWithProgress[],
    };
  }
  return {
    childLearningOutcomes:
      levelTwoLearningOutcomes as LearningOutcomeWithProgress[],
    relatedLearningOutcomes: [],
  };
};

export const getSkillsTagListData = (
  skills?: LearningOutcomeSkills,
  level?: LearningOutcomeLevels | null
) => {
  if (!skills)
    return {
      extraSkillsAmount: 0,
      skillsToShow: [],
      showExtraSkillsText: false,
    };

  const isLevelTwoAndHasMoreThanFive =
    level === LearningOutcomeLevels.Three && skills?.length > 4;

  const isLevelOneAndHasMoreThanOne =
    level === LearningOutcomeLevels.One && skills?.length > 1;

  const isLevelTwoAndHasMoreThanTwo =
    level === LearningOutcomeLevels.Two && skills?.length > 2;

  const skillsToShow = isLevelOneAndHasMoreThanOne
    ? skills?.slice(0, 1)
    : isLevelTwoAndHasMoreThanTwo
    ? skills?.slice(0, 2)
    : isLevelTwoAndHasMoreThanFive
    ? skills?.slice(0, 4)
    : skills;

  return {
    skillsToShow,
    extraSkillsAmount: skills?.length - skillsToShow.length,
    showExtraSkillsText:
      isLevelOneAndHasMoreThanOne ||
      isLevelTwoAndHasMoreThanTwo ||
      isLevelTwoAndHasMoreThanFive,
  };
};

export const sortSkillsByClassification = (skills?: LearningOutcomeSkills) => {
  if (!skills?.length) return [];

  const sortedSkills = skills?.sort((a, b) => {
    if (a?.classification === SkillClassification.Language) {
      return -1;
    }
    if (b?.classification === SkillClassification.Subject) {
      return 1;
    }
    return 0;
  });

  return sortedSkills;
};

export const getLearningOutcomeTrackingData = (
  level?: LearningOutcomeLevels | null,
  id?: LearningOutcomeWithProgress['id'],
  latestScore?: number | null,
  outcome?: LearningOutcomeWithProgress['outcome'],
  skills?: LearningOutcomeSkills
) => {
  const skillSlugs = skills?.map((skill) => skill?.slug);

  return {
    misc: JSON.stringify({
      last_assessed_score: latestScore,
      LO_level: level,
      skills: skillSlugs,
      title: outcome,
      unique_id: id,
    }),
  };
};

export const sortLearningOutcomesByCompletionDate = (
  learningOutcomes?: LearningOutcomeWithProgress[] | null
) => {
  if (!learningOutcomes?.length) return [];

  return [...learningOutcomes]?.sort((a, b) => {
    const aDate = a?.progress?.completion?.createdAt;
    const bDate = b?.progress?.completion?.createdAt;

    if (!aDate && !bDate) return 0;
    if (!aDate) return 1;
    if (!bDate) return -1;

    return new Date(bDate).getTime() - new Date(aDate).getTime();
  });
};

export const getCompletedLearningOutcomes = (
  learningOutcomes:
    | LearningOutcomesQuery['learningOutcomes']
    | SkillAreaQuery['learningOutcomes']
) => {
  if (!learningOutcomes?.length) return [];

  // TODO: update when we have progress data
  const learningOutcomesWithProgress = learningOutcomes?.map(
    (learningOutcome) => ({
      ...learningOutcome,
      progress: learningOutcome1.progress,
    })
  ) as LearningOutcomeWithProgress[];

  return learningOutcomesWithProgress?.filter(
    (learningOutcome) => learningOutcome?.progress?.completion?.createdAt
  );
};

export const getLearningOutcomesForSkill = (
  learningOutcomes?: SkillAreaQuery['learningOutcomes'],
  skillSlug?: string
) => {
  if (!learningOutcomes?.length || !skillSlug) return [];

  const learningOutcomesForSkill = learningOutcomes.filter(
    (learningOutcome) => {
      const { skills } = getLearningOutcomeCardSkills(
        learningOutcome as LearningOutcomeWithProgress
      );
      return skills.some((skill) => skill?.slug === skillSlug);
    }
  );

  return learningOutcomesForSkill;
};
