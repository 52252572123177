import {
  CodeSnippet,
  ReviewCard,
  Skill,
  SkillClassification,
} from '@mono/data-codegen/client/graphql-gateway/graphql';

import {
  LearningOutcomeContentModules,
  LearningOutcomeLevels,
  LearningOutcomePaths,
  LearningOutcomeTracks,
  LearningOutcomeWithProgress,
} from './types';

export const mockSkills = [
  {
    id: 'skill-one',
    title: 'Java',
    slug: 'test-skill-slug',
  },
  {
    id: 'skill-two',
    title: 'Computer science',
    slug: 'test-skill-slug-two',
  },
] as Skill[];

export const mockReviewCards = [
  {
    id: 'testid',
    title: 'Review Card Title',
    body: 'Review Card description',
    codeSnippets: [
      {
        id: 'test-snippet-id',
        text: 'code snippet text',
        language: 'javascript',
      },
    ] as CodeSnippet[],
    images: [
      {
        alt: 'alt text',
        url: 'https://example.com/image.png',
      },
    ],
  },
  {
    id: 'testid-two',
    title: 'Review Card Title Two',
    body: 'Review Card description two',
    codeSnippets: [],
    images: [],
  },
  {
    id: 'testid-three',
    title: 'Review Card Title three',
    body: 'Review Card description three',
    codeSnippets: [],
    images: [],
  },
  {
    id: 'testid-four',
    title: 'Review Card Title four',
    body: 'Review Card description four',
    codeSnippets: [],
    images: [],
  },
  {
    id: 'testid-five',
    title: 'Review Card Title five',
    body: 'Review Card description five',
    codeSnippets: [],
    images: [],
  },
] as ReviewCard[];

export const mockLastReviewCard = [
  {
    id: 'testid-last',
    title: 'Review Card Title Last',
    body: 'Review Card description last',
    codeSnippets: [],
    images: [],
  },
];

export const learningOutcomeContentModule = {
  id: 'test-module',
  slug: 'test-slug',
  title: 'Test Module',
  reviewCards: mockReviewCards,
  contentItems: [
    {
      id: '1',
      type: 'Lesson',
    },
  ],
  skills: [
    {
      id: '5',
      title: 'Java',
      slug: 'java',
      classification: SkillClassification.Language,
    },
    {
      id: '4',
      title: 'Web development',
      slug: 'web-development',
      classification: SkillClassification.Subject,
    },
    {
      id: '7',
      title: 'Mobile development',
      slug: 'mobile-development',
      classification: SkillClassification.Subject,
    },
    {
      id: '6',
      title: 'Computer Science',
      slug: 'computer-science',
      classification: SkillClassification.Subject,
    },
    {
      id: '8',
      title: 'Web design',
      slug: 'web-design',
      classification: SkillClassification.Subject,
    },
  ] as Skill[],
  tracks: [
    {
      id: 'test-track',
      title: 'Test Track',
      difficulty: 'Beginner',
      shortDescription: 'Test Track short description',
      contentItemCount: 5,
      metrics: {
        medianDurationHours: 10,
      },
      proExclusive: false,
      grantsCertificate: true,
      enrollmentStatus: 'Enrolled',
      type: 'Track',
      released: true,
      slug: 'test-track-slug',
    },
  ],
  paths: [
    {
      id: 'test-path',
      title: 'Test Path',
      difficulty: 'Beginner',
      shortDescription: 'Test Path short description',
      enrollmentStatus: 'inProgress',
      contentItemCount: 5,
      trackCount: 5,
      metrics: {
        medianDurationHours: 10,
      },
      slug: 'test-path-slug',
      type: 'Path',
      released: true,
      contentModules: [],
    },
  ] as LearningOutcomePaths,
};

export const mockSkillSlugs = [
  'java',
  'web-development',
  'mobile-development',
  'computer-science',
  'web-design',
];

export const relatedlearningOutcome2: Partial<LearningOutcomeWithProgress> = {
  id: 'test-lo-id-2',
  level: LearningOutcomeLevels.Two,
  outcome:
    'Write and manipulate Java programs using variables, classes, and methods',
  contentModules: [
    learningOutcomeContentModule,
  ] as LearningOutcomeContentModules,
  progress: {
    completion: {
      createdAt: '2025-01-17T16:49:15Z',
    },
    latestScore: 100,
  },
};

export const relatedlearningOutcome3: Partial<LearningOutcomeWithProgress> = {
  id: 'test-lo-id-3',
  level: LearningOutcomeLevels.Three,
  outcome:
    'Implement advanced Java coding skills, understand data structures, algorithms, and tackle technical interviews',
  contentModules: [
    learningOutcomeContentModule,
  ] as LearningOutcomeContentModules,
  progress: {
    completion: {
      createdAt: '2025-01-20T16:49:15Z',
    },
    latestScore: 20,
  },
};

export const learningOutcome1: LearningOutcomeWithProgress = {
  id: 'test-lo-id-1',
  level: LearningOutcomeLevels.One,
  outcome: 'Solve advanced Java code challenges using dynamic programming',
  contentModules: [
    learningOutcomeContentModule,
  ] as LearningOutcomeContentModules,
  progress: {
    completion: {
      createdAt: '2024-12-05T18:30:00Z',
    },
    latestScore: 50,
    latestScoreAchievedAt: '2024-06-17T16:49:15Z',
    highestScore: 100,
    highestScoreAchievedAt: '2024-08-25T16:49:15Z',
  },
  levelOneLearningOutcomes: [],
  levelTwoLearningOutcomes: [
    relatedlearningOutcome2 as LearningOutcomeWithProgress,
  ],
  levelThreeLearningOutcomes: [
    relatedlearningOutcome3 as LearningOutcomeWithProgress,
  ],
};

export const learningOutcome2: LearningOutcomeWithProgress = {
  id: 'test-lo-id-2',
  level: LearningOutcomeLevels.Two,
  outcome:
    'Write and manipulate Java programs using variables, classes, and methods',
  contentModules: [
    learningOutcomeContentModule,
  ] as LearningOutcomeContentModules,
  tracks: [
    {
      id: 'test-track',
      title: 'Test Track',
      difficulty: 'Beginner',
      shortDescription: 'Test Track short description',
      enrollmentStatus: 'inProgress',
      grantsCertificate: true,
      proExclusive: false,
      contentItemCount: 5,
      metrics: {
        medianDurationHours: 10,
      },
      slug: 'test-track-slug',
      released: true,
      type: 'Track',
      contentModules: [learningOutcomeContentModule],
    },
  ] as LearningOutcomeTracks,
  progress: {
    completion: {
      createdAt: '2024-10-22T19:00:30Z',
    },
    latestScore: 100,
    latestScoreAchievedAt: '2024-06-17T16:49:15Z',
    highestScore: 100,
    highestScoreAchievedAt: '2024-06-17T16:49:15Z',
  },
  levelOneLearningOutcomes: [
    learningOutcome1,
    { ...learningOutcome1, id: 'test-lo' },
  ],
  levelTwoLearningOutcomes: [],
  levelThreeLearningOutcomes: [
    relatedlearningOutcome3 as LearningOutcomeWithProgress,
  ],
};

export const learningOutcome3: LearningOutcomeWithProgress = {
  id: 'test-lo-id-3',
  level: LearningOutcomeLevels.Three,
  outcome:
    'Implement advanced Java coding skills, understand data structures, algorithms, and tackle technical interviews',
  contentModules: [
    learningOutcomeContentModule,
  ] as LearningOutcomeContentModules,
  progress: {
    completion: {
      createdAt: '2024-09-10T12:05:20Z',
    },
  },
  paths: [
    {
      id: 'test-path',
      title: 'Test Path',
      difficulty: 'Beginner',
      shortDescription: 'Test Path short description',
      enrollmentStatus: 'inProgress',
      contentItemCount: 5,
      trackCount: 5,
      metrics: {
        medianDurationHours: 10,
      },
      slug: 'test-path-slug',
      type: 'Path',
      released: true,
      contentModules: [learningOutcomeContentModule],
    },
  ] as LearningOutcomePaths,
  levelOneLearningOutcomes: [learningOutcome1],
  levelTwoLearningOutcomes: [
    learningOutcome2,
    { ...learningOutcome2, id: 'test-lo', progress: null },
    { ...learningOutcome2, id: 'test-lo-2', progress: { completion: null } },
    {
      ...learningOutcome2,
      id: 'test-lo-3',
      progress: { completion: { createdAt: null } },
    },
    {
      ...learningOutcome2,
      id: 'test-lo-4',
      progress: { completion: { createdAt: '2024-08-22T19:00:30Z' } },
    },
  ],
  levelThreeLearningOutcomes: [],
};

export const learningOutcomes = [
  learningOutcome1,
  {
    ...learningOutcome1,
    id: 'test-lo-id-4',
    progress: { completion: { createdAt: '2024-11-15T14:15:45Z' } },
  },
  learningOutcome2,
  learningOutcome3,
  {
    ...learningOutcome2,
    id: 'test-lo-id-5',
    progress: { completion: { createdAt: '2024-08-05T07:45:10Z' } },
  },
  {
    ...learningOutcome1,
    id: 'test-lo-id-6',
    outcome: 'Implement and understand various search algorithms',
    progress: { completion: { createdAt: '2024-06-25T16:10:50Z' } },
  },
  {
    ...learningOutcome1,
    id: 'test-lo-id-7',
    outcome: `Utilize Java's built-in data structures like ArrayLists, LinkedList and HashMaps`,
    progress: { completion: { createdAt: '2024-07-18T22:20:05Z' } },
  },
  {
    ...learningOutcome3,
    id: 'test-lo-id-8',
    progress: { completion: { createdAt: '2024-05-10T10:55:40Z' } },
  },
  {
    ...learningOutcome2,
    id: 'test-lo-id-9',
    progress: { completion: { createdAt: '2024-04-02T08:20:15Z' } },
  },
  {
    ...learningOutcome2,
    id: 'test-lo-id-10',
    outcome: 'Non completed Learning outcome',
    progress: { completion: { createdAt: null } },
  },
];

export const nonCompletedLearningOutcomes = [
  { ...learningOutcome1, progress: { completion: {} } },
  {
    ...learningOutcome1,
    id: 'test-lo-id-4',
    progress: { completion: {} },
  },
  { ...learningOutcome2, progress: { completion: {} } },
];

export const relatedLearningOutcomes = [learningOutcome2];
